import React, {useState, useEffect} from 'react';
import { Grid, Link as MaterialLink, Collapse, Button } from '@mui/material';

import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/reducers";
import PropTypes from "prop-types";

import BellIcon from '../../assets/images/bell-icon.svg';
import AlertSeperator from '../../assets/images/alert-seperater-line.svg';
import '../../styles/yellowBanner.scss';
import { getBanner } from '../../redux/actionTypes/banner';

let selectedLanguage = localStorage.getItem("selectedLanguage")

if (selectedLanguage === null) {
  selectedLanguage = "en";
} else {
  selectedLanguage = selectedLanguage?.split("-")?.[0];
}

const YellowBanner = (props) => {
  const [language, setLanguage] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [isEnableAlert, setIsEnableAlert] = useState(false);
  const [yellowBanner, setYellowBanner] = useState([]);
  const [yellowBannerTwo, setYellowBannerTwo] = useState([]);
  const [yellowBannerThree, setYellowBannerThree] = useState([]);

  // useEffect(()=>{
  //  props.getBanner();
  // },[]);

  const handleAlert = () => {
    setShowAlert((prev) => !prev)
  }

  // useEffect(()=>{ 
  //   if (props?.banner && props?.banner?.banner?.data?.length) {
  //     setIsEnableAlert(true);
  //     const data = props?.banner?.banner?.data;
  //     const selectedLangBanner = data.find(item=>item.language == selectedLanguage);
  //     setYellowBanner(selectedLangBanner.dsc);
  //   }
  // },[props.banner]);

  useEffect(()=>{
    const bannerDataTwo = [
      {"id":1,"code":"YELLOW","dsc":"As of December 1, 2024, the HIP amount is now $20 a month for all SNAP households. For more information, visit: <a href='https://www.mass.gov/HIPChange' target='_self'>www.mass.gov/HIPChange</a>.","language":"en"},
      {"id":2,"code":"YELLOW","dsc":"A partir del 1.° de diciembre de 2024, el monto del Programa de Incentivos Saludables (HIP, por sus siglas en inglés) será de $20 al mes para todos los grupos familiares del Programa de Asistencia Nutricional Complementaria (SNAP, por sus siglas en inglés). Para obtener más información, visite: <a href='https://www.mass.gov/HIPChange' target='_self'>www.mass.gov/HIPChange</a>.","language":"es"},
      {"id":3,"code":"YELLOW","dsc":"A partir de 1º de dezembro de 2024, o valor do HIP passará a ser de US$ 20 por mês para todos os domicílios do SNAP. Para obter mais informações, acesse: <a href='https://www.mass.gov/HIPChange' target='_self'>www.mass.gov/HIPChange</a>.","language":"pt"},
      {"id":4,"code":"YELLOW","dsc":"自 2024 年 12 月 1 日起，所有 SNAP 家庭的 HIP 金额均为每月 20 美元。如需了解详情, 请访问: <a href='https://www.mass.gov/HIPChange' target='_self'>www.mass.gov/HIPChange</a>.","language":"zh"},
      {"id":5,"code":"YELLOW","dsc":"Tính đến ngày 1 tháng 12 năm 2024, số tiền HIP hiện là 20 đô la một tháng cho tất cả các hộ gia đình SNAP. Để biết thêm thông tin, hãy truy cập: <a href='https://www.mass.gov/HIPChange' target='_self'>www.mass.gov/HIPChange</a>.","language":"vi"}];
      
    // const bannerData = []; // To enable the line when you need to hide the yellow banner
    if (bannerDataTwo && bannerDataTwo.length) {
      const selectedLangBannerTwo = bannerDataTwo.find(item=>item.language == selectedLanguage);

      setYellowBannerTwo(selectedLangBannerTwo.dsc);
      setIsEnableAlert(true);
    }
  },[]);

  useEffect(() => { 
    setLanguage(props.language)
  }, [props.language])
    return (
      <>
      { isEnableAlert && (<>
        <Grid container display={{md: 'block' }} className="yellow-wrapper">
          <Grid container maxWidth="lg" className="yellow-wrapper-banner">
            <Grid item xs={12} sx={{ mx: 2 }} display={{ md: 'block' }} className="banner div-center" >
              <Grid item xs={12} sx={{ mx: 2}} display="flex" alignItems="center" className="alerts-outer">
                <Grid item xs={12} sm={9} lg={9} md={9} className="div-center alerts-inner" display="flex" justifyContent="flex-start"
                  alignItems="center"
                  fontSize={{ xs: '14px', lg: '24px', md: '24px' }}>
                  <img src={BellIcon} alt="Alert Bell Logo" height="27" width="30" style={{ marginTop: 5, marginRight: 10 }} />
                  <span className="ml-08 alert-fnt alert-txt">{language?.alerts}</span>
                  {/* <img className="ml-08 mr-08 alert-separator" src={AlertSeperator} alt="Separator line" height="25" /> */}
                  {/* <p className="ml-08 alert-fnt alert-update">{language?.alertHeading}</p> */}
                </Grid>
                <Grid item xs={12} sm={3} lg={3} md={3} display="flex" justifyContent="flex-end"  className="margib-btn alert-btn">
                  <Button className="alert-black-button" onClick={handleAlert}>
                    {showAlert ? language?.hideAlerts : language?.showAlerts}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={showAlert} sx={{ background: '#fef9e8' }} id="yellowBanner" classes={{ wrapper: 'alignCenter' }}>
          <Grid container maxWidth="lg" className="wrapper-banner" sx={{ display: showAlert ? 'block' : 'none'}}>
            <Grid item xs={12} sx={{ mx: 2 }}  className="banner div-center">
              <Grid item xs={12} sx={{ mx: 2 }} display="flex" alignItems="center" className="alerts-outer">
                <Grid  item xs={12} sm={12} lg={12} md={12} className="div-center alerts-inner" display="flex" justifyContent="flex-start"
                  alignItems="center" fontSize={{ xs: '14px', lg: '24px', md: '24px' }} >
                  <Grid className="banner-txt" item xs={12} lg={12} md={12}  textAlign="justify" color={'#555555'} >
                    <p dangerouslySetInnerHTML={{ __html: yellowBannerTwo}} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
        </>
      )}
      </>
    )
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getBanner: (payload) => dispatch(getBanner(payload)),
  }
}

YellowBanner.propTypes = {
  language: PropTypes.object,
  data: PropTypes.object,
  isEnableAlert: PropTypes.bool,
  // getBanner: PropTypes.func,
  // banner: PropTypes.object
};

export default connect( mapStateToProps, mapDispatchToProps )(YellowBanner);